import { createRouter, createWebHistory } from 'vue-router'
const Home = () =>
    import ('../components/AppHome')
const ComingSoon = () =>
    import ('../components/ComingSoon')
const News = () =>
    import ('../components/News')
const NewsDetails = () =>
    import ('../components/News-Details')
const EventDetails = () =>
    import ('../components/Event-Details')
const Events = () =>
    import ('../components/Events')
const UnivDetails = () =>
    import ('../components/Univ-Details')
const Univ = () =>
    import ('../components/Univ')

const Faculties = () =>
    import ('../components/Faculties')
const Branshes = () =>
    import ('../components/Branshes')
const Person = () =>
    import ('../components/Person')
const Page = () =>
    import ('../components/Page')
const NotFound = () =>
    import ('../components/NotFound')
const ContactUs = () =>
    import ('../components/ContactUs')
const Department = () =>
    import ('../components/Department')
const DepartmentDetails = () =>
    import ('../components/Department-details')
const Servic = () =>
    import ('../components/Servicess.vue')

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    { path: '/:pathMatch(.*)*', component: NotFound },
    {
        path: '/ComingSoon',
        name: 'ComingSoon',
        component: ComingSoon
    },
    {
        path: '/News/:type/:BrnId',
        name: 'News-With-Type-And_BrnId',
        component: News
    },
    {
        path: '/NewsDetails/:type/:id/:BrnId',
        name: 'NewsDetails-with-id-And-Type-And-BrnId',
        component: NewsDetails
    },
    {
        path: '/ContactUs/',
        name: 'ContactUs',
        component: ContactUs
    },
    {
        path: '/Events/:type',
        name: 'Events-With-Type',
        component: Events
    },
    {
        path: '/Event-Details/:type/:id',
        name: 'Event-with-id-And-Type',
        component: EventDetails
    },
    {
        path: '/Department/:type/:BrnId',
        name: 'Department-with-BrnId-And-Type',
        component: Department
    },
    {
        path: '/Department-Details/:type/:BrnId/:id',
        name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
        component: DepartmentDetails
    },
    {
        path: '/UnivDetails/:type/:id',
        name: 'Univ-with-id-And-Type',
        component: UnivDetails
    },

    {
        path: '/Univ/:type',
        name: 'Univ-with-Type',
        component: Univ
    },

    {
        path: '/Faculties/:id',
        name: 'Faculties-with-id',
        component: Faculties
    },

    {
        path: '/Branshes/:id',
        name: 'Branshes-with-id',
        component: Branshes
    },
    {
        path: '/Leader/:type/:id',
        name: 'Leader-with-id-And-Type',
        component: Person
    },
    {
        path: '/Page/:type/:id',
        name: 'Page-with-id-And-Type',
        component: Page,
    },
    {
        path: '/Service/:id',
        name: 'Service',
        component: Servic
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router